import {Box, Container, Link, Typography} from '@mui/material';

import {StatusCodePageProps} from './statusCodePage.types';

const StatusCodePage = (props: StatusCodePageProps) => {
  const {
    code = '404',
    linkText = 'Go to homepage',
    linkUrl = '/',
    text = 'Page not found',
  } = props;
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography sx={{fontSize: '6rem !important'}} variant="h1">
          {code}
        </Typography>
        <Typography variant="h1">{text}</Typography>
        <Link href={linkUrl}>{linkText}</Link>
      </Box>
    </Container>
  );
};

export default StatusCodePage;
